@import url(https://fonts.googleapis.com/css?family=Karla);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}
p{
  font-size: 16px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.nav-tabs>li.active>a{
  border: none !important;
  border-bottom: 1px solid #E4223B !important;
  color: #E4223B !important;
  font-weight: bolder !important;
}



.nav-tabs{
  height: 7vh;
  border:none !important;
  font-size: 3vh !important;
}

[data-qa="close-button-mobile"] {
            display: none !important;
}

[data-qa="popup-mode-popup"] {
            width:100vw!important;
            height: 100vh!important;
            top:0!important;
            left:0!important;
}

[data-qa="popup-close-button"] {
            display: none !important;
}

.nav-tabs>li{
  width: 50% !important;
  text-align: center !important;
}
.nav-tabs>li>a{
  color:#979797 !important;
}

/* .tab-content{
  height:63vh;
  overflow-y: scroll;
} */
 

/*Slot machine*/
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg)
  }
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg)
  }
}



#repeatButton {
  border: none;
  font-weight: bolder;
  background: #E21936;
  width: 250px;
  height: 48px;
  margin: 0 auto;
  display: block;
  cursor: pointer;
  border-radius: 50px;
  color: white
  
  
}

.icons { 
  display: inline-block;
  width: 128px;
  height: 564px;
  overflow: hidden;
  background: #fff url(/static/media/sprite.925179e6.png) repeat-y;
  will-change: backgroundPosition;
  transition: 0.3s background-position ease-in-out;
  padding: 0 80px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
}

.icons:nth-child(2) {
  margin: 0 10px;
}

* { box-sizing: border-box; }

.spinner-container {
  overflow: hidden;
  height: 632px;  
  padding: 2em;
  -webkit-transform: translate(-50%, -50%) scale(.7, .7);
          transform: translate(-50%, -50%) scale(.7, .7);
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  transition: 0.3s -webkit-transform;
  transition: 0.3s transform;
  transition: 0.3s transform, 0.3s -webkit-transform;
}

.app-wrap.winner-false {
  -webkit-animation: linear 1s spin;
          animation: linear 1s spin;
}

.spinner-container::after {
  position: absolute;
  content: '';
  display: block;
  height: 180px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 30px;
  left: 30px;
  background: rgba(255,46,71,0.2); 
  border-top: 1px solid red;
  border-bottom: 1px solid red; 
}

.gradient-fade {
  position: absolute;
  top: 28px;
  right: 28px;
  bottom: 39px;
  left: 28px;
  background: linear-gradient(
    to bottom, 
    rgba(91,91,91,0.3) 0%, 
    rgba(91,91,91,0.0) 20%, 
    rgba(91,91,91,0.0) 80%, 
    rgba(91,91,91,0.3) 100%
  );
}

@media screen and (max-width: 1000px) {
  .spinner-container {
    -webkit-transform: translate(-50%, -50%) scale(.7, .7);
            transform: translate(-50%, -50%) scale(.7, .7);
  }
}

@media screen and (max-width: 500px) {
  .spinner-container {
    -webkit-transform: translate(-50%, -50%) scale(.7, .7);
            transform: translate(-50%, -50%) scale(.7, .7);
  }
}
@media screen and (max-width: 500px) {
  .spinner-container {
    -webkit-transform: translate(-50%, -50%) scale(.5, .5);
            transform: translate(-50%, -50%) scale(.5, .5);
  }
}

/*End slot*/




.hand{
  position: absolute; 
  z-index: 99; 
  width: 300px; 
  top:160px;
  left: 180px;
  -webkit-animation-name: shakeHand;
          animation-name: shakeHand;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
  }

  @-webkit-keyframes shakeHand {
  0%   {-webkit-transform: rotateZ(0deg);transform: rotateZ(0deg); opacity: 1;}
  25%  {-webkit-transform: rotateZ(25deg);transform: rotateZ(25deg); opacity: 1;}
  50%  {-webkit-transform: rotateZ(0deg);transform: rotateZ(0deg); opacity: 1;}
  75%  {-webkit-transform: rotateZ(25deg);transform: rotateZ(25deg); opacity: 1;}
  100%  {-webkit-transform: rotateZ(0deg);transform: rotateZ(0deg); opacity: 0;}
  
}

  @keyframes shakeHand {
  0%   {-webkit-transform: rotateZ(0deg);transform: rotateZ(0deg); opacity: 1;}
  25%  {-webkit-transform: rotateZ(25deg);transform: rotateZ(25deg); opacity: 1;}
  50%  {-webkit-transform: rotateZ(0deg);transform: rotateZ(0deg); opacity: 1;}
  75%  {-webkit-transform: rotateZ(25deg);transform: rotateZ(25deg); opacity: 1;}
  100%  {-webkit-transform: rotateZ(0deg);transform: rotateZ(0deg); opacity: 0;}
  
}

/* Survey */

.survey-text{
font-family: 'Karla', sans-serif;
font-size: 4vw;
line-height: 8.3vw;
text-align: center;
}

.survey-quote{
  font-size: 10vw;
  color: #E21936;

}

.typeform-container{
  width: 100vw;
  height: 100vh;
}
